import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			display: 'flex',
			padding: '10px 30px'
		},
		formControl: {
			margin: theme.spacing(3)
		},
	}),
);

const App = () => {
	const classes = useStyles();
	const [lines, setLines] = React.useState([]);
	const [audio, setAudio] = React.useState('');

	const handleChange = (e) => {
		const line = parseInt(e.target.name, 10);
		const checked = e.target.checked;

		let newLines = [...lines];
		if (checked) {
			newLines.push(line);
		} else {
			newLines = newLines.filter((i) => i !== line);
		}

		setLines(newLines);
	};

	const play = () => {
		const selectedLines = [...lines].sort((a, b) => a - b);
		const filename = `./files/${selectedLines.join('_')}.mp3`;
		setAudio(filename);
	};

	const getAudio = () => {
		if (!audio) {
			return null;
		}

		return (
			<div style={{ padding: '30px 0 0 50px' }}>
				<audio src={audio} controls />
			</div>
		);
	};

	const optionsDisabled = lines.length >= 5;
	const btnDisabled = lines.length < 2;

	return (
		<div className={classes.root}>
			<h1 style={{ marginRight: 50 }}>Select lines</h1>

			<FormControl component="fieldset" className={classes.formControl}>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={lines.indexOf(1) !== -1}
								onChange={handleChange}
								name="1"
								disabled={lines.indexOf(1) === -1 && optionsDisabled}
							/>
						}
						label="Bass"
					/>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={lines.indexOf(2) !== -1}
								onChange={handleChange}
								name="2"
								disabled={lines.indexOf(2) === -1 && optionsDisabled}
							/>
						}
						label="Cello"
					/>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={lines.indexOf(3) !== -1}
								onChange={handleChange}
								name="3"
								disabled={lines.indexOf(3) === -1 && optionsDisabled}
							/>
						}
						label="Clarinet & Guitar"
					/>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={lines.indexOf(4) !== -1}
								onChange={handleChange}
								name="4"
								disabled={lines.indexOf(4) === -1 && optionsDisabled}
							/>
						}
						label="Flute and Guitar"
					/>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={lines.indexOf(5) !== -1}
								onChange={handleChange}
								name="5"
								disabled={lines.indexOf(5) === -1 && optionsDisabled}
							/>
						}
						label="Viola"
					/>
					<FormControlLabel
						control={
							<Checkbox
								color="primary"
								checked={lines.indexOf(6) !== -1}
								onChange={handleChange}
								name="6"
								disabled={lines.indexOf(6) === -1 && optionsDisabled}
							/>
						}
						label="Violin"
					/>
				</FormGroup>
				<FormHelperText style={{ marginBottom: 20 }}>Select between 2 and 5 lines</FormHelperText>

				<Button
					color="primary"
					variant="contained"
					disabled={btnDisabled}
					onClick={play}
				>
					Play &raquo;
				</Button>
			</FormControl>

			{getAudio()}
		</div>
	);
};

export default App;
